import { Link } from 'react-router-dom';

import { Container, Content, Logo, Menu, Social } from './styles';

import logoImg from '../../assets/images/logo.png';

export const Header = () => {
  return (
    <Container>
      <Content>
        <Logo>
          <Link to="/">
            <img src={logoImg} alt="logo" />
          </Link>
        </Logo>

        {/* <Menu>
          <ul>
            <li>
              <Link to="/">Inicio</Link>
            </li>
            <li>
              <a href="/">Sobre nós</a>
              <ul>
                <li>
                  <a href="/">A Igreja</a>
                </li>
                <li>
                  <a href="/">Conselho</a>
                </li>
                <li>
                  <a href="/">História</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="/">Grupos</a>
              <ul>
                <li>
                  <a href="/">Kids</a>
                </li>
                <li>
                  <a href="/">Adolas</a>
                </li>
                <li>
                  <a href="/">Jovens</a>
                </li>
                <li>
                  <a href="/">Homens</a>
                </li>
                <li>
                  <a href="/">Mulheres</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="/">Equipes</a>
              <ul>
                <li>
                  <a href="/">Web Design</a>
                </li>
                <li>
                  <a href="/">SEO</a>
                </li>
                <li>
                  <a href="/">Design</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="/">Mensagens</a>
            </li>
            <li>
              <a href="/">Contribua</a>
            </li>
            <li>
              <a href="/">Contato</a>
            </li>
          </ul>
        </Menu>
        <Social>
          <ul>
            <li>
              <a href="/">i</a>
            </li>
            <li>
              <a href="/">yt</a>
            </li>
            <li>
              <a href="/">s</a>
            </li>
          </ul>
        </Social> */}
      </Content>
    </Container>
  );
};
