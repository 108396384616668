import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { Container, Content, Scrollbar, ScrollThumb } from './style';

type ScrollbarContainerProps = {
  children: React.ReactNode;
  className?: string;
};

const SCROLL_BOX_MIN_HEIGHT = 20;

const getElement = (element: HTMLElement | null): HTMLElement => {
  if (element !== null && element !== undefined) {
    return element;
  }
  return document.createElement('div');
};

export default function ScrollbarContainer({
  children,
  className,
  ...restProps
}: ScrollbarContainerProps) {
  const [hovering, setHovering] = useState(false);
  const [scrollBoxHeight, setScrollBoxHeight] = useState(SCROLL_BOX_MIN_HEIGHT);
  const [scrollBoxTop, setScrollBoxTop] = useState(0);
  const [lastScrollThumbPosition, setScrollThumbPosition] = useState(0);
  const [isDragging, setDragging] = useState(false);

  const { height: innerHeight } = useWindowDimensions();

  const scrollHostRef = useRef<HTMLDivElement>(null);

  const handleMouseOver = useCallback(() => {
    !hovering && setHovering(true);
  }, [hovering]);

  const handleMouseOut = useCallback(() => {
    !!hovering && setHovering(false);
  }, [hovering]);

  const handleDocumentMouseUp = useCallback(
    (e) => {
      if (isDragging) {
        e.preventDefault();
        setDragging(false);
      }
    },
    [isDragging]
  );

  const handleDocumentMouseMove = useCallback(
    (e) => {
      if (isDragging) {
        e.preventDefault();
        e.stopPropagation();
        const scrollHostElement = getElement(scrollHostRef.current);
        const scrollHostParent = getElement(scrollHostElement.parentElement);
        const { scrollHeight, offsetHeight } = scrollHostParent;

        let deltaY = e.clientY - lastScrollThumbPosition;
        let percentage = deltaY * (scrollHeight / offsetHeight);
        console.log('deltaY:', deltaY);
        console.log('percentage:', percentage);

        setScrollThumbPosition(e.clientY);
        setScrollBoxTop(
          Math.min(
            Math.max(0, scrollBoxTop + deltaY),
            offsetHeight - scrollBoxHeight
          )
        );
        scrollHostElement.scrollTop = Math.min(
          scrollHostElement.scrollTop + percentage,
          scrollHeight - offsetHeight
        );
      }
    },
    [isDragging, lastScrollThumbPosition, scrollBoxHeight, scrollBoxTop]
  );

  const handleScrollThumbMouseDown = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setScrollThumbPosition(e.clientY);
    setDragging(true);
    console.log('handleScrollThumbMouseDown');
  }, []);

  const handleScroll = useCallback(() => {
    if (!scrollHostRef) {
      console.log(false);
      return;
    }
    const scrollHostElement = getElement(scrollHostRef.current);
    const scrollHostParent = getElement(scrollHostElement.parentElement);
    console.log(scrollHostParent);
    const { scrollTop, scrollHeight, offsetHeight } = scrollHostParent;

    let newTop = (scrollTop / scrollHeight) * offsetHeight;
    // newTop = newTop + parseInt(scrollTop, 10);
    newTop = Math.min(newTop, offsetHeight - scrollBoxHeight);
    setScrollBoxTop(newTop);
  }, [scrollBoxHeight]);

  useEffect(() => {
    const scrollHostElement = getElement(scrollHostRef.current);
    const { clientHeight } = scrollHostElement;
    const scrollThumbPercentage = innerHeight / clientHeight;
    const scrollThumbHeight = Math.max(
      scrollThumbPercentage * innerHeight,
      SCROLL_BOX_MIN_HEIGHT
    );
    setScrollBoxHeight(scrollThumbHeight);
    document.addEventListener('scroll', handleScroll, true);
    return function cleanup() {
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll, innerHeight]);

  useEffect(() => {
    //this is handle the dragging on scroll-thumb
    document.addEventListener('mousemove', handleDocumentMouseMove);
    document.addEventListener('mouseup', handleDocumentMouseUp);
    document.addEventListener('mouseleave', handleDocumentMouseUp);
    return function cleanup() {
      document.removeEventListener('mousemove', handleDocumentMouseMove);
      document.removeEventListener('mouseup', handleDocumentMouseUp);
      document.removeEventListener('mouseleave', handleDocumentMouseUp);
    };
  }, [handleDocumentMouseMove, handleDocumentMouseUp]);

  return (
    <Container
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      innerHeight={innerHeight + 'px'}
    >
      <Content
        ref={scrollHostRef}
        className={`${className ?? ''}`}
        {...restProps}
      >
        {children}
        <Scrollbar style={{ opacity: hovering || isDragging ? 1 : 0 }}>
          <ScrollThumb
            style={{ height: scrollBoxHeight, top: scrollBoxTop }}
            onMouseDown={handleScrollThumbMouseDown}
          />
        </Scrollbar>
      </Content>
    </Container>
  );
}
