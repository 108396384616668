import styled from 'styled-components';
import { devices } from '../../utils/ResponsiveRules';
import ytImg from '../../assets/images/yt.webp';
import igrejaImg from '../../assets/images/igreja.webp';

export const Container = styled.main`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const VideoContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: -1000;
  
  background: url(${igrejaImg}) rgba(0,0,0, 0.6);
  background-size: cover;
  background-blend-mode: darken;
  background-position: center;

  div { 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    margin: 0;
    z-index: 1;
  }

  h1, h2 {
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    font-size: 2em;
    color: #fff;
  }

  h2 {
    font-weight: 300;
    font-size: 1em;
    margin-bottom: 1em;
  }

  video {
    filter: grayscale(1) brightness(.3);
  }

  img { 
    width: auto;
    height: auto;
    min-width: 100vw;
    min-height: 100vh;
    filter: grayscale(1) brightness(.3);
  }

  @media ${devices.tablet} {
    div { 
      font-size: 1.5rem;
    }
  }

  @media ${devices.laptopL} {
    div {
      font-size: 2rem;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: fit-content;
  margin: 1rem;

  .encontros {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;

    h3, ul {
      flex: 1;
      list-style: none;
    }

    h3 {
      text-align: right;
      font-size: 3rem;
      text-transform: lowercase;
      margin-bottom: .5rem;
    }

    & > ul > li {
      font-weight: bold;
      margin-top: .5rem;

      & > ul > li {
        font-weight: normal;
      }
    }
  }

  .youtube {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;

    background: url(${ytImg}) rgba(0,0,0, 0.6);
    background-size: cover;
    background-blend-mode: darken;
    background-position: center;
    padding: 7rem 2rem;
    border-radius: 10px;

    text-decoration: none;

    transition: background 0.5s ease;

    h3 {
      color: white;
      text-align: center;
      text-transform: lowercase;
      
      transition: opacity 0.5s ease;
    }
    
    svg { 
      position: absolute;
      opacity: 0;
      
      transition: opacity 0.5s ease;
    }

    &:hover {
      background: url(${ytImg}) rgba(0,0,0, 0.8);
      background-size: cover;
      background-blend-mode: darken;

      h3 {
        opacity: .4;
      }

      svg {
        opacity: 1;
      }
    }
  }

  @media ${devices.mobileS} {
    .youtube {
      font-size: 1.5rem;
    }
  }

  @media ${devices.mobileL} {
    .youtube {
      font-size: 2rem;
    }
  }

  @media ${devices.tablet} {
    flex-direction: row;
  }

  @media ${devices.laptop} {
    .encontros {
      flex: 2;
    }    
  }
`;