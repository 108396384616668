import { Link } from 'react-router-dom';

import { FaInstagram, FaMapMarkedAlt, FaYoutube } from 'react-icons/fa';

import {
  AddressContainer,
  Column,
  Columns,
  ColumnTitle,
  Container,
  Content,
  Copyright,
  SocialButtons,
} from './styles';

export const Footer = () => {
  const mapUrl =
    'https://www.google.com/maps/dir/?api=1&travelmode=driving&origin=&destination=108%2C+R.+Adley%2C+52+-+Cruzeiro+do+Sul%2C+Cariacica+-+ES';

  return (
    <Container>
      <Content>
        <Columns>
          <Column>
            <ColumnTitle>Onde estamos</ColumnTitle>
            <AddressContainer>
              <p>
                108, R. Adley, 52
                <br />
                Cruzeiro do Sul, Cariacica - ES
              </p>
              <a href={mapUrl} target="_blank" rel="noreferrer">
                <FaMapMarkedAlt size="30" />
              </a>
            </AddressContainer>
          </Column>
          {/* <Column>
            <ColumnTitle>Links úteis</ColumnTitle>
            <p></p>
          </Column> */}
          <Column>
            <ColumnTitle>Redes sociais</ColumnTitle>
            <SocialButtons>
              <a
                href="https://www.youtube.com/c/1oIgrejaPresbiterianadeCampoGrandeCariacica"
                id="youtube"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube size="25" />
              </a>
              <a
                href="https://instagram.com/primeiraipcg"
                id="instagram"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram size="25" />
              </a>
            </SocialButtons>
          </Column>
        </Columns>
      </Content>
      <Copyright>
        <p>
          © 2022&nbsp;
          <Link to="/">Primeira Igreja Presbiteriana de Campo Grande</Link>.
          Todos os direitos reservados.
        </p>
      </Copyright>
    </Container>
  );
};
