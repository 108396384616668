import { Routes, Route } from 'react-router-dom';

import { Home } from './pages/Home';
import ScrollbarContainer from './components/ScrollbarContainer';

function App() {
  return (
    <ScrollbarContainer>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </ScrollbarContainer>
  );
}

export default App;
