import styled from 'styled-components';
import { devices } from '../../utils/ResponsiveRules';

export const Container = styled.footer`
  background: #0f0f0f;
`;

export const Content = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${devices.tablet} {
    padding: 3rem 4rem;
  }
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;

  @media ${devices.tablet} {
    flex: 1;
    flex-direction: row;
  }
`;

export const Column = styled.div`
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0 2rem 0 .5rem;

  .social {
    display: flex;
    flex-direction: row;

    .social { 
    }
  }

  @media ${devices.tablet} {
    text-align: left;
  }
`;

export const SocialButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  a { 
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;
    border-radius: 50%;
    color: #fff;
    margin-right: .5rem;

    &#youtube {
      background: #ff0000;
    }

    &#instagram {
      background: #e1306c;
    }

    &#whatsapp {
      background: #25d366;
    }

    &:hover {
      filter: brightness(0.8);
    }
  }

  @media ${devices.tablet} {
    justify-content: left;
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  p { padding-bottom: 1rem; }

  a {
    color: #fff;

    &:hover {
      filter: brightness(0.8);
    }
  }

  @media ${devices.tablet} {
    flex-direction: row;
    justify-content: space-between;
    
    p {
      padding: 1rem;
      padding-left: 0;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      color: black;
      padding: .7rem;
    }
  }

  @media ${devices.laptop} {
    p {
      flex: 1;
      background: rgba(255, 255, 255, 0.1);
      padding-left: 1rem;
    }

    a {
      padding: 1.3rem;
    }
  }
`;

export const ColumnTitle = styled.h6`
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: .7rem;
`;

export const Copyright = styled.div`
  background: black;
  color: white;
  text-align: center;
  font-size: .8rem;
  padding: 0.5rem 1rem;

  a {
    text-decoration: none;
    color: white;
    font-weight: bold;
  }
`;