import styled from 'styled-components';

type ContainerProps = {
  innerHeight: string;
};

export const Container = styled.div<ContainerProps>`
  position: relative;
  height: ${props =>  props.innerHeight || '100%'};
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Content = styled.div`
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Scrollbar = styled.div`
  width: 5px;
  height: 100vh;
  right: 0;
  top: 0;
  position: fixed;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.35);
`;

export const ScrollThumb = styled.div`
  width: 4px;
  height: 20px;
  margin-left: 1px;
  position: absolute;
  opacity: 1;
  top: 0;
  background-color: rgba(0, 0, 0, 0.55);
`;