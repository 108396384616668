import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  justify-content: center;
  height: 0;
  overflow: visible;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75rem;
  height: fit-content;
  z-index: 1;
`;

export const Logo = styled.div`

  a {
    display: block;
    padding: .5rem;
    height: 5rem;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

export const Menu = styled.nav`
  display: flex;

  ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;

    li {

      a {
        padding: 1rem .5rem;
        display: block;
        color: black;
        text-transform: uppercase;
        text-decoration: none;
        font-size: .8rem;
        color: white;

        transition: background .5s, color .5s;

        box-sizing: border-box;
      }

      ul {
        position: absolute;
        margin-top: 3px;
        background-color: #fff;
        display: none;
      }

      &:hover {
        & > a {
          background-color: #f0f0f0;
          color: #070707;
        }

        ul {
          display: block;

          li {
            width: 150px;
          }
        }
      }
    }
  }

  & > ul > li {
    border-bottom: 0px solid #000;
    transition: border .5s ease;
        
    &:hover {
      border-bottom: 3px solid #000;

      a {
      }
    }
  }
`;

export const Social = styled.div`
  display: flex;

  ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;

    li {

      a {
        padding: .5rem;
        width: 1rem;
        text-align: center;
        display: block;
        color: black;
        text-transform: uppercase;
        text-decoration: none;
        font-size: .8rem;

        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }
`;