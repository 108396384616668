import { Container, Content, VideoContainer } from './styles';

import { Header } from '../../components/Header';

import { FaPlay } from 'react-icons/fa';

// import videoBg from '../../assets/videos/landscape.webm';
import { Footer } from '../../components/Footer';
// import { Button } from '../../components/Button';

export const Home = () => {
  console.log(window.innerWidth);

  return (
    <>
      <Header />
      <Container>
        <VideoContainer>
          <div>
            <h2>Primeira Igreja Presbiteriana de Campo Grande</h2>
            <h1>
              A comunidade
              <br />
              da cruz de Cristo
            </h1>
          </div>
          {/* <video className="videoTag" autoPlay loop muted>
            <source src={videoBg} type="video/webm" />
          </video> */}
          {/* <img src={igrejaImg} alt="" /> */}
        </VideoContainer>
        <Content>
          <div className="encontros">
            <h3>Encontros</h3>
            <ul>
              <li>
                Domingos
                <ul>
                  <li>Escola Bíblica Dominical - 9h</li>
                  <li>Culto - 10h30</li>
                  <li>Culto - 18h</li>
                </ul>
              </li>
              <li>
                Terças
                <ul>
                  <li>Lendo Marcos - 19h30</li>
                </ul>
              </li>
            </ul>
          </div>
          <a
            href="https://www.youtube.com/c/1oIgrejaPresbiterianadeCampoGrandeCariacica"
            className="youtube"
            rel="noreferrer"
            target="_blank"
          >
            <h3>
              Acompanhe
              <br />
              no YouTube
            </h3>
            <FaPlay size={80} color="white" />
          </a>
        </Content>
      </Container>
      <Footer />
    </>
  );
};
